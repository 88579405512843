<template>
  <div class="taskPublish">
    <div class="banner-width">
      <div>
        <Banner/>
      </div>
      <!-- 任务发布表单 -->
      <div class="task-Publish">
        <div class="task-Publish-center">
          <!-- 草稿部分 -->
          <div class="taks-Publish-draft" v-show="haveDraft">
            <div class="taks-Publish-draft-left">
              <Isvg icon-class="prompt"/>
            </div>
            <div class="taks-Publish-draft-right">
              <li>已为您自动加载4天前正在编辑的项目，</li>
              <li>您可以直接在页面中继续编辑，点击“放弃”将清除表单信息并永久删除草稿！</li>
              <button>放弃并编辑草稿</button>
            </div>
          </div>

          <!-- 任务标题 -->
          <div class="task-Publish-Form" v-loading="editPageLoadShow">
            <el-form :model="taskForm" :rules="taskRules" ref="taskForm" class="demo-taskForm">
              <div v-for="(step, i) in stepArray" :key="i">
                <!-- 请填写任务标题 -->
                <el-form-item v-show="step.key == 0 && step.isShow" label="请填写任务标题" prop="subject">
                  <el-input :autofocus="true" v-model="taskForm.subject" class="task-input-content"
                            placeholder="要求简洁清晰, 请输入至少一个技能。" style="width: 720px;" show-word-limit
                            maxlength="50"/>
                </el-form-item>

                <!-- 请详情描述一下您的任务需求 -->
                <el-form-item v-show="step.key == 0 && step.isShow" label="请详情描述一下您的任务需求" prop="content">
                  <el-input
                      type="textarea"
                      v-model="taskForm.content"
                      placeholder="请具体描述一下任务的工作内容和要求，最多可输入300个字符。"
                      style="width: 720px;color: #575757"
                      show-word-limit
                      maxlength="300"
                      :autosize="{ minRows: 2, maxRows: 10}"/>
                </el-form-item>

                <!-- 上传附件 -->
                <el-form-item v-show="step.key == 0 && step.isShow" label="上传附件" prop="attr_ids" class="task-accessory">
                  <p style="color: #575757;font-size: 14px">(有助于更好的描述您的需求)</p>
                  <div class="task-accessory1" v-for="(attrI, attrKey) in attrs" :key="attrKey">
                    <div class="task-accessory1-left">附件{{ (attrKey + 1) }}</div>
                    <div class="task-accessory1-right">
                      <i :class="'iconfont '+ attrI.icon" class="b mr-5 wh-22"></i>
                      <span>{{ attrI.name }}</span>
                    </div>
                    <Isvg icon-class="task-close" @click.native="removeAttr(attrI, attrKey)"/>
                  </div>
                  <el-upload
                      v-loading="uploadLoading"
                      ref="uploadAttr"
                      class="upload-demo"
                      drag
                      accept="text/csv,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,image/gif,image/jpeg,image/png,application/zip,application/rar"
                      :action="postUrl"
                      :data="{ file_type: 'doc' }"
                      :headers="headers"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload"
                      :on-success="uploadAttrSuccess"
                      multiple
                  >
                    <div class="upload-center">
                      <Isvg icon-class="task-upload"/>
                      <span class="el-upload__text">拖拽或点击此处添加文件（文件总大小不超过25MB）</span>
                    </div>
                  </el-upload>
                </el-form-item>

                <!-- 完成该工作需要什么技能 -->
                <el-form-item v-loading="loading" v-if="step.key == 1 && step.isShow" label="完成该工作需要什么技能"
                              prop="skill_ids" class="task-work">
                  <Skills
                      :title="'系统猜测您的任务属于'"
                      :show-title="true"
                      :cat-style="{width:'638px'}"
                      :style-attrs="{width:'638px'}"
                      :checked_skill_cat_id="taskForm.cat_id"
                      :checked_skills="taskForm.skills"
                      @skillCallBack="skillCallBack"/>
                </el-form-item>

                <!-- 工作地点 -->
                <el-form-item v-show="step.key == 2 && step.isShow" prop="work_location" label="工作地点">
                  <div class="task-workAddress-border">
                    <div
                        class="task-workAddress-border2"
                        v-for="(locationItem, locationIndex) in workLocationStyle"
                        :key="locationIndex"
                        :style="locationItem.status == taskForm.work_location ? locationItem.in_style : locationItem.out_style"
                        @click="changeWorkLocation(locationItem.status)"
                    >
                      <div class="task-workAddress-border1-imgBorder">
                        <Isvg
                            :icon-class="locationItem.status == taskForm.work_location ? locationItem.in_icon : locationItem.out_icon"
                            alt=""/>
                      </div>
                      <div class="task-workAddress-border1-fontBorder">
                        <strong>{{ locationItem.name }}</strong>
                        <br/>
                        <div class="mb-10"></div>
                        <p>{{ locationItem.text }}</p>
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <!-- 请设置工作地址 -->
                <el-form-item v-show="step.key == 2 && step.isShow && taskForm.work_location == 2" label="请设置工作地址"
                              prop="work_province_id">
                  <Region @reginCallBack="regionBack" :checkedValue="taskForm.work_province_id"
                          :regionStyle="{width:'300px'}"/>
                </el-form-item>
                <el-form-item v-show="step.key == 2 && step.isShow && taskForm.work_location == 2" prop="work_address">
                  <el-input v-model="taskForm.work_address" placeholder="请输入详细办公地点"/>
                </el-form-item>

                <div class="task-amount" v-show="step.key == 2 && step.isShow">
                  <div class="title"><span class="r">*</span> <span>此项目的预算</span></div>

                  <div class="content">
                    <div class="money">
                      <el-form-item class="person" prop="plan_hire_count">
                        <span>*</span> <span> 预计雇佣 </span>
                        <el-input v-model="taskForm.plan_hire_count" type="number" placeholder="雇佣人数">
                          <span class="unit" slot="suffix">人</span>
                        </el-input>
                      </el-form-item>

                      <el-form-item class="person" prop="fixed_budget_min" style="margin-right: unset !important;">
                        <span>*</span> <span> 预算金额 </span>
                        <el-input
                            type="number"
                            v-model="taskForm.fixed_budget_min"
                            min="1">
                          <span class="unit" slot="suffix">{{Number(taskForm.plan_hire_count)>1?'元/人': '元'}}</span>
                        </el-input>
                      </el-form-item>

                      <span class="sectionBtn" v-if="sectionShow"> - </span>
                      <span class="sectionBtn" @click="changeSection(true)" v-else>预算区间</span>

                      <el-form-item class="person" prop="fixed_budget_max" v-show="sectionShow">
                        <el-input
                            type="number"
                            v-model="taskForm.fixed_budget_max"
                            style="width: 150px;"
                            min="1">
                          <span class="unit" slot="suffix"> {{Number(taskForm.plan_hire_count)>1?'元/人': '元'}} </span>
                        </el-input>
                      </el-form-item>

                      <span class="sectionBtn" v-if="sectionShow" @click="changeSection(false)">取消</span>
                    </div>
                    <el-alert
                        style="margin-bottom: 20px;padding: 15px"
                        v-show="taskForm.fixed_budget_min>0"
                        title="预计项目费用"
                        type="info"
                        :closable="false"
                        show-icon>
                      <template>
                        预设金额 {{ planPrice }} 元, 雇佣 {{ taskForm.plan_hire_count }} 人,
                        此项目预计需要费用（ {{ planPrice }} x {{ taskForm.plan_hire_count }} ）=
                        {{ planPrice * taskForm.plan_hire_count }} 元
                      </template>
                    </el-alert>

                    <div class="task-fixed-money-border-period-border">
                      <div class="task-fixed-money-border-period-border-left">
                        <el-radio-group v-model="taskForm.pay_cycle">
                          <el-radio-button
                              :label="payCycleItem.status"
                              v-for="(payCycleItem, payCycleIndex) in payCycleStyle"
                              :key="payCycleIndex"
                              @change="changePayCycle(payCycleItem.status)">
                            <i :class="payCycleItem.status==3?'el-icon-suitcase':'el-icon-pie-chart'"></i>
                            {{ payCycleItem.name }}
                          </el-radio-button>
                        </el-radio-group>
                      </div>
                      <div class="task-fixed-money-border-period-border-right" v-show="taskForm.pay_cycle == 3">
                        <span>项目100%验收完成后，酬劳一次性发放。</span>
                      </div>
                      <div class="task-stages" v-show="taskForm.pay_cycle == 4">
                        <div class="task-stages-center">
                          <div class="task-stages-header"><span>可设定分期期数和比例，每个分期阶段验收通过后发放酬劳。</span></div>
                          <div class="task-stages-bottom">
                            <div class="task-stages-left">
                              <ul>
                                <li class="task-stages-left-three" v-for="(stageitem, stageindex) in taskForm.stages"
                                    :key="stageindex">
                                  <div class="task-stages-lef1">
                                    <strong>第 {{ stageindex + 1 }} 期</strong>
                                  </div>
                                  <div class="task-stages-left2">
                                    <span>支出比例</span>
                                    <el-select v-model="stageitem.scale" size="mini" placeholder="请选择">
                                      <el-option
                                          v-for="item in stagesPercents"
                                          :key="item"
                                          :value="item">
                                        <span style="float: left">{{ item }} %</span>
                                      </el-option>
                                    </el-select>

                                    <span class="task-stages-percent">%</span>
                                  </div>
                                  <div class="task-stages-left3" @click="removeStage(stageindex)">
                                    <Isvg icon-class="task-delete-selected"/>
                                  </div>
                                </li>
                                <div class="task-stages-add" @click="addStage">
                                  <span>+</span>
                                  新增一个周期
                                </div>
                              </ul>
                              <el-alert title="百分比最大只能是100" type="error" :closable="false" v-show="currentStagePercent!=100"/>
                            </div>
                            <div class="task-stages-right">
                              <ul>
                                <li>
                                  <span>温馨提示</span>
                                  ：当前仅是一个预期设
                                </li>
                                <li>定值，确定合作前均可以根据实</li>
                                <li>际情况变动。（默认为您推荐</li>
                                <li>5:4:1三期）。</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <el-form-item style="margin-top: 20px" prop="invoice_type">
                      <span class="r">*</span> <span> 发票类型 </span>
                      <el-select style="margin-left: 10px" filterable v-model="taskForm.invoice_type" clearable>
                        <el-option
                            v-for="(typeItem, typeIndex) in invoiceTypeList"
                            :key="typeIndex"
                            :label="typeItem"
                            :value="typeItem"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <!--  请设置投标发布/截止日期 -->
                <div v-show="step.key == 3 && step.isShow" class="task-end-time">
                  <el-form-item label="招标截止时间" style="margin-bottom: unset !important;">
                    <span>截止日期之前，自由职业者可向您发出求职申请</span>
                    <div class="task-time-border">
                      <div class="task-start-time-border">
                        <el-form-item prop="bid_end_time">
                          <strong>日期</strong>
                          <el-date-picker
                              v-model="taskForm.bid_end_time"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              format="yyyy年 M月 d日 H时"
                              type="datetime"
                              :picker-options="EndTimeOption"
                              placeholder="请选择截止时间"/>
                        </el-form-item>
                      </div>

                      <div class="task-start-time-border">
                        <el-form-item prop="bid_end_time">
                          <strong>时间</strong>
                          <el-time-picker
                              value-format="yyyy-MM-dd HH:mm:ss"
                              v-model="taskForm.bid_end_time"
                              placeholder="任意时间点"/>
                        </el-form-item>

                      </div>
                    </div>
                  </el-form-item>
                </div>

                <!-- 其它设置 -->
                <div v-show="step.key == 3 && step.isShow" class="task-other">
                  <el-form-item label="其它设置项" prop="other" style="margin-bottom: unset !important;">
                    <br/>
                    <el-form-item prop="is_open_bids" style="margin-bottom: unset !important;">
                      <el-checkbox true-label="y" false-label="n" v-model="taskForm.is_open_bids">公开投标者列表</el-checkbox>
                    </el-form-item>

                    <el-form-item prop="is_ask_coin" style="margin-bottom: unset !important;">
                      <el-checkbox true-label="y" v-model="taskForm.is_ask_coin">投标诚意金（要求投标者支付一定的爪币，未中标或流标，将退还给投标者）
                      </el-checkbox>
                    </el-form-item>

                    <span v-show="taskForm.is_ask_coin">
											<el-form-item prop="ask_coin" style="display: inline-block">
												<el-input type="number" v-model.number="taskForm.ask_coin" size="mini" placeholder="1~20个"
                                  style="width: 100px"/>
											</el-form-item>
											<span style="color: '#575757'; font-size: 12px" class="ml-10">爪币</span>
										</span>
                  </el-form-item>
                </div>

                <!-- 确认您的联系方式 -->
                <div class="task-phone" v-show="step.key==3&&step.isShow&&!isLogin">
                  <el-form-item label="确认您的联系电话" prop="mobile">
                    <span>手机号码将自动注册账号，八爪灵工依法保护你的隐私权益。</span>
                    <br/>
                    <el-input v-model="taskForm.mobile" placeholder="请输入您的手机号" style="width: 300px"/>
                  </el-form-item>
                  <div class="mb-10"></div>
                  <el-form-item prop="code">
                    <el-input v-model="taskForm.code" placeholder="请输入验证码" style="width: 170px"/>
                    <button type="button" :style="canClick ? {} : { background: '#b5b5b5' }" @click="toSendCode">
                      {{ codemsg }}
                    </button>
                  </el-form-item>
                </div>

                <!-- 按钮 -->
                <div v-show="step.key == 3 && step.isShow" class="task-two-buttons">
                  <div class="task-two-button-center">
                    <el-button type="text" @click="toPreview" class="task-one-button" round>预览</el-button>
                    <el-button :loading="publishLoading" type="primary" class="task-two-button"
                               style="line-height: 12px;" @click="toSubmit" round>保存
                    </el-button>
                  </div>
                </div>

              </div>
            </el-form>
            <div class="bottom-btn">
              <el-button
                  :class="itemNextBtn.disabled ? '':'bule' "
                  :disabled="itemNextBtn.disabled"
                  v-show="false"
                  @click="toNext(itemNextBtn.key)"
                  v-for="(itemNextBtn, indexNextBtn) in nextStepBtn"
                  :key="indexNextBtn"
                  size="mini"
                  round
              >
                下一步
              </el-button>
            </div>
            <el-dialog class="dialog-border" :visible.sync="dialogVisible" :width="'800px'" :before-close="handleClose"
                       center>
              <div class="task-dialog-center">
                <div class="task-dialog-center-left">
                  <div class="task-dialog-center-left1">
                    <h2>{{ taskForm.subject }}</h2>
                    <p>{{ taskForm.content }}</p>
                  </div>
                  <div class="task-dialog-center-left2">
                    <div class="task-dialog-center-left2-font1"><span>附件：</span></div>
                    <div class="task-dialog-center-left2-font2" v-if="attrs.length>0">
                      <li v-for="(attrItem, attrIndex) in attrs" :key="attrIndex">
                        <i :class="'iconfont '+ attrItem.icon" class="b mr-5 wh-22"></i>
                        <span>{{ attrItem.name }} </span>
                      </li>
                    </div>
                    <div class="task-dialog-center-left2-font2" v-else>
                      <span style="font-size: 12px;color: #575757">未选择附件</span>
                    </div>
                  </div>
                  <div class="task-dialog-center-left3">
                    <p>
                      <strong>技能:</strong>
                    <ul class="span-box">
                      <span v-for="( sk, skindex ) in current_skills" :key="skindex"> {{ sk.name }}</span>
                    </ul>
                    </p>
                  </div>
                </div>
                <div class="task-dialog-center-right">
                  <div class="task-dialog-center-right-header">
                    <Isvg icon-class="task-budget"/>
                    <span v-if="taskForm.pay_way==1">
                      项目预算：
                      ¥ {{
                        taskForm.fixed_budget_max == '' ? taskForm.fixed_budget_min : taskForm.fixed_budget_min + ' - ' + taskForm.fixed_budget_max
                      }}元
                    </span>
                    <span v-if="taskForm.pay_way==2">
                      项目预算：
                      ¥ {{
                        taskForm.hourly_budget_max == '' ? taskForm.hourly_budget_min : taskForm.hourly_budget_min + ' - ' + taskForm.hourly_budget_max
                      }} /小时
                    </span>
                  </div>
                  <div class="task-dialog-center-right-three-border">
                    <div class="task-dialog-center-right-three-border1" v-show="taskForm.pay_channel!=''">
                      <div class="task-dialog-center-right-three-border1-img">
                        <Isvg icon-class="task-platform"/>
                      </div>
                      <span v-if="taskForm.pay_channel==1">平台托管</span>
                      <span v-if="taskForm.pay_channel==2">合作银行</span>
                    </div>
                    <div class="task-dialog-center-right-three-border1">
                      <div v-if="taskForm.pay_way==1" class="task-dialog-center-right-three-border1-img">
                        <Isvg icon-class="task-hourly-billing"/>
                      </div>
                      <span v-if="taskForm.pay_way==1">支付固定价</span>

                      <div v-if="taskForm.pay_way==2" class="task-dialog-center-right-three-border1-img">
                        <Isvg icon-class="task-hourly-billing"/>
                      </div>
                      <span v-if="taskForm.pay_way==2">按小时计费</span>
                    </div>
                    <div class="task-dialog-center-right-three-border1" v-show="taskForm.pay_way==2">
                      <div v-show="taskForm.pay_cycle==1" class="task-dialog-center-right-three-border1-img">
                        <Isvg icon-class="task-week-billing"/>
                      </div>
                      <span v-show="taskForm.pay_cycle==1">按周结算({{ taskForm.plan_period_count }}期)</span>
                      <div v-show="taskForm.pay_cycle==2" class="task-dialog-center-right-three-border1-img">
                        <Isvg icon-class="task-month-billing"/>
                      </div>
                      <span v-show="taskForm.pay_cycle==2">按月结算({{ taskForm.plan_period_count }}期)</span>
                    </div>
                  </div>
                  <div class="task-dialog-back1">
                    <Isvg icon-class="task-location"/>
                    <span v-if="taskForm.work_location==2">工作地点：{{
                        checkedAddressName(regionData[0], regionData[1], regionData[2])
                      }}</span>
                    <span v-if="taskForm.work_location==1">工作地点: 在线工作</span>
                  </div>
                  <div class="task-dialog-back2">
                    <Isvg icon-class="task-people"/>
                    <span>雇佣人数：{{ taskForm.plan_hire_count }}人</span>
                  </div>
                  <div class="task-dialog-back3">
                    <Isvg icon-class="task-publish-time"/>
                    <ul>
                      <li>招标截至：{{ taskForm.bid_end_time }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
								<el-button @click="dialogVisible = false">返回修改</el-button>
								<el-button :loading="publishLoading" type="primary" @click="toSubmit" style="background-color:#00a2e6">立即发布</el-button>
							</span>
            </el-dialog>
          </div>
        </div>
      </div>

      <div style="height: 20px;" ref="PosOne"></div>
    </div>
  </div>
</template>

<script>
import Region from '@/components/Region/Region';
import Banner from '@/components/Banner';
import Skills from '@/components/Skills'
import {
  getPrivateInfo,
  getTaskConfigData,
  getRecommandskills,
  getSkillsCategory,
  updateTask
} from '@/api/task';
import {requestSendCode} from '@/api/index';
import {getToken} from "@/utils/auth";
import {isNumber} from "lodash-es";
import {getInvoiceServices} from "@/api/invoice";

export default {
  name: 'taskEdit',
  components: {
    Region,
    Banner,
    Skills
  },
  data() {
    var validatorLocation = (rule, value, callback) => {
      if (this.taskForm.work_location == 2 && value == '') {
        callback(new Error('选择线下办公，需要填写地址'));
      } else {
        callback();
      }
    };
    var validatorDayMaxHour = (rule, value, callback) => {
      var reg = /(^[1-4]\d*$)/
      if (this.taskForm.pay_way == 2) {
        if (value < this.taskConfig.max_hour_per_max[0] || value > this.taskConfig.max_hour_per_max[1] || !reg.test(value)) {
          callback(new Error('单日最大工作时长是' + this.taskConfig.max_hour_per_max[0] + '~' + this.taskConfig.max_hour_per_max[1] + '个小时'));
          console.log('单日最大工作时长是' + this.taskConfig.max_hour_per_max[0] + '~' + this.taskConfig.max_hour_per_max[1] + '个小时')
        } else {
          callback()
        }
        callback()
      } else {
        callback()
      }
    };
    var validatorHourlyBudget = (rule, value, callback) => {
      var errorMessage = '';
      if (this.taskForm.pay_way == 2) {
        var reg = /(^[1-9]\d*$)/
        if ((value < this.taskConfig.hourly_budget[0] || value > this.taskConfig.hourly_budget[1]) || !reg.test(value)) {
          callback(new Error(this.taskConfig.hourly_budget[0] + '~' + this.taskConfig.hourly_budget[1] + '之间'));
        } else {
          callback();
        }
        this.taskForm.hourly_budget_max = this.taskForm.hourly_budget_max == '' ? this.taskForm.hourly_budget_min : this.taskForm.hourly_budget_max;
        if (value < this.taskConfig.hourly_budget[0] || value > this.taskConfig.hourly_budget[1]) {
          errorMessage = '请设置在 ' + this.taskConfig.hourly_budget[0] + '~' + this.taskConfig.hourly_budget[1] + ' 元';
        } else {
          if (parseInt(this.taskForm.hourly_budget_min) > parseInt(this.taskForm.hourly_budget_max)) {
            errorMessage = '最高价要大于最低价';
          }
        }

        if (errorMessage == '') {
          callback();
        }
        console.log(errorMessage)
        callback(new Error(errorMessage));
      } else {
        callback();
      }
    };
    var validatorFixedBudget = (rule, value, callback) => {
      const reg = /(^[1-9]\d*$)/
      if (this.taskForm.pay_way == 1) {
        value = Number( value )
        this.taskForm.fixed_budget_min = value
        if (value < this.taskConfig.fixed_budget[0] || value > this.taskConfig.fixed_budget[1] || !reg.test(value)) {
          console.log('固定价格区间在错误 min')
          callback(new Error('固定价格区间在 ' + this.taskConfig.fixed_budget[0] + '~' + this.taskConfig.fixed_budget[1] + ' 元'))
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatorMaxFixedBudget = (rule, value, callback) => {
      if (this.taskForm.pay_way == 1) {
        value = Number( value )
        value = this.taskForm.fixed_budget_max = value == 0 ? this.taskForm.fixed_budget_min : value;

        if (parseInt(this.taskForm.fixed_budget_max) < parseInt(this.taskForm.fixed_budget_min)) {
          callback(new Error('预算金额输入错误'))
        } else {
          if (parseInt(value) < this.taskConfig.fixed_budget[0] || parseInt(value) > this.taskConfig.fixed_budget[1]) {
            console.log('固定价格区间在错误 max' + value)
            return callback(new Error('固定价格区间在 ' + this.taskConfig.fixed_budget[0] + '~' + this.taskConfig.fixed_budget[1] + ' 元'))
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };
    var validatorHourWeek = (rule, value, callback) => {
      if (this.taskForm.pay_way == 2) {
        var message = '';
        var reg = /(^[1-9]\d*$)/
        switch (parseInt(this.taskForm.pay_cycle)) {
          case 1:
            if (value < this.taskConfig.expect_week[0] || value > this.taskConfig.expect_week[1] || !reg.test(value)) {
              message = '预计周区间在' + this.taskConfig.expect_week[0] + '~' + this.taskConfig.expect_week[1] + '周';
              this.taskForm.plan_period_count = 1
            }
            break;
          case 2:
            if (value < this.taskConfig.expect_month[0] || value > this.taskConfig.expect_month[1] || !reg.test(value)) {
              message = '预计月区间在' + this.taskConfig.expect_month[0] + '~' + this.taskConfig.expect_month[1] + '月';
              this.taskForm.plan_period_count = 1
            }
            break;
        }
        if (message == '') {
          callback();
        }
        console.log(message)
        callback(new Error(message));
      } else {
        callback();
      }
    };
    var validatorAskCoin = (rule, value, callback) => {
      var reg = /(^[1-9]\d*$)/
      if (this.taskForm.is_ask_coin && value == '') {
        console.log('请填写投标诚意金')
        callback(new Error('请填写投标诚意金'));
      } else {
        if (value > 20 || !reg.test(value)) {
          this.taskForm.ask_coin = 1
        }
        callback();
      }
    };
    var validatorMobile = (rule, value, callback) => {
      if (!this.isLogin) {
        if (value == '') {
          console.log('请填写手机号码')
          callback(new Error('请填写手机号码'));
        } else {
          let reg = /^1([0-9])\d{9}$/;
          if (!reg.test(value)) {
            console.log('请填写正确的手机号码')
            callback(new Error('请填写正确的手机号码'));
          }
        }
        callback();
      } else {
        callback();
      }
    };
    var validatorCode = (rule, value, callback) => {
      if (!getToken()) {
        if (value == '') {
          callback(new Error('请填写验证码'));
        } else {
          callback();
        }
        callback();
      } else {
        callback();
      }
    }
    var validatorBidEndTime = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请设置招标截止时间'));
      } else {
        callback()
      }
    };
    var validatorSubject = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请填写任务标题'));
      }

      var len = value.length;
      if (len < 3 || len > 50) {
        callback(new Error('标题长度需要在3到50个字符'));
      } else {
        callback();
      }
    }
    var validatorContent = (rule, value, callback) => {
      if (value == '') {
        console.log('请填写任务需求')
        callback(new Error('请填写任务需求'));
      }

      var len = value.length;
      if (len < 10) {
        console.log('任务需求长度需要在10字及以上才可')
        callback(new Error('任务需求长度需要在10字及以上才可'));
      } else {
        callback();
      }
    }
    var validatorPlanHireCount = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入雇佣人数'));
      }
      var reg = /(^[1-9]\d*$)/
      if ((value < 1 || value > 1000) || !reg.test(value)) {
        this.taskForm.plan_hire_count = 1
        callback(new Error('雇佣人数请设置在 1~1000之间的正整数'));
      } else {
        callback();
      }
    }
    return {
      editPageLoadShow: false,
      postUrl: '/api/upload',
      headers: {Authorization: `Bearer ${getToken()}`},
      uploadLoading: false,
      taskConfig: {},
      nextStepBtn: [
        {key: 0, isShow: true, disabled: true},
        {key: 1, isShow: false, disabled: true},
        {key: 2, isShow: false, disabled: true},
        {key: 3, isShow: false, disabled: true}
      ],
      stepArray: [{key: 0, isShow: true}, {key: 1, isShow: true}, {key: 2, isShow: true}, {key: 3, isShow: true}],
      workLocationStyle: [
        // 1在线工作 2本地工作
        {
          status: 1,
          name: '在线办公',
          text: '对工作地点无要求，项目成果在线提交即可。',
          in_style: {'border-color': '#00a5e6'},
          in_icon: 'task-line-work-yes',
          out_style: {'border-color': '#b5b5b5'},
          out_icon: 'task-line-work-no',
        },
        {
          status: 2,
          name: '线下办公',
          text: '需要在约定的办公地点现场办公。',
          in_style: {'border-color': '#00a5e6'},
          in_icon: 'task-unline-work-yes',
          out_style: {'border-color': '#b5b5b5'},
          out_icon: 'task-unline-work-no'
        }
      ],
      payWayStyle: [
        // 付款模式 1-固定费率 2-时薪
        {
          status: 1,
          name: '支付固定价格',
          text: '固定价格，在工作完成后发放付款',
          memo: '最适合一次性任务。',
          in_style: {'border-color': '#00a5e6'},
          in_icon: 'task-fixed-select-yes',
          out_style: {'border-color': '#b5b5b5'},
          out_icon: 'task-fixed-select-no',
        },
        {
          status: 2,
          name: '按小时付费',
          text: '根据小时费率雇用并按计费小时数付费，',
          memo: '最适合正在进行的工作。',
          in_style: {'border-color': '#00a5e6'},
          in_icon: 'task-hourly-select-yes',
          out_style: {'border-color': '#b5b5b5'},
          out_icon: 'task-hourly-select-no'
        }
      ],
      payCycleStyle: [
        // 1-week 2-month 3-full 4-staging
        {
          status: 3,
          name: '一次性支付',
          text: '',
          in_style: {'background-color': '#00B4FF', color: '#fff', border: 'none'},
          in_icon: 'task-one-pay-white',
          out_style: {'background-color': '#fff', color: '#000'},
          out_icon: 'task-one-pay-grey'//require('@/icons/svg/task/一次性支付-灰24X24.svg')
        },
        {
          status: 4,
          name: '分期支付',
          text: '',
          in_style: {'background-color': '#00a2e6', color: '#fff', border: 'none'},
          in_icon: 'task-stages-pay-white',
          out_style: {'background-color': '#fff', color: '#000'},
          out_icon: 'task-stages-pay-grey',
        }
      ],
      taskId: 0,
      redirect_url: '',
      haveDraft: false, // 是否有草稿
      skills: [],
      current_skills: [],
      attrs: [],
      recommand_skills: [],
      categorys: [],
      regionData: [0, 0, 0],
      invoiceTypeList: [],

      dialogVisible: false,
      publishLoading: false,
      sectionShow: false,
      taskForm: {
        work_location: 1, // 工作地点
        work_province_id: '',
        work_county_id: '',
        work_city_id: '',
        work_address: '',
        pay_way: 1, // 支付方式 1-固定费率 2-时薪
        hourly_budget_min: '', //时薪 最小
        hourly_budget_max: '', //时薪 最大
        max_hour_per_max: '', //时薪 单日最大工时
        plan_period_count: '', // 预计多少周期
        fixed_budget_min: '', //最低预算金额/元
        fixed_budget_max: '', //最高预算金额/元
        pay_cycle: 3, // 支付周期 1-week 2-month 3-full 4-staging
        pay_channel: 1, // 托管方式 1-平台 2-合作银行
        subject: '', // 任务标题
        content: '', // 需求
        attr_ids: [], // 附件
        skill_cat_id: 0,
        skill_ids: '',
        plan_hire_count: '', //雇佣人数
        cat_id: '', // 技能分类
        stages: [{cycle: 1, scale: 50}, {cycle: 2, scale: 40}, {cycle: 3, scale: 10}], // 分期比例
        is_ask_coin: 0, //爪币诚意金
        ask_coin: '', //爪币诚意金
        bid_end_time: '', // 截止招标时间
        other: '', //其它设置项
        is_open_bids: 'n', //公开投标者列表
        mobile: '', //手机号码
        code: '', //验证码
        checkedc: '', //阅读服务
        invoice_type: ''//发票类型
      },
      stagesPercents: [90, 80, 70, 60, 50, 40, 30, 20, 10],
      currentStagePercent: 100,
      loading: false, //加载
      states: ['Alabama'], //选择出来的值
      taskRules: {
        subject: [
          {required: true, message: '请填写任务标题', trigger: 'blur'},
          {validator: validatorSubject}
        ],
        content: [
          {
            required: true,
            message: '请详情描述一下您的任务需求',
            trigger: 'change'
          },
          {validator: validatorContent}
        ],
        skill_ids: [
          {
            required: true,
            message: '请输入完成该工作需要什么技能',
            trigger: 'blur'
          }
        ],
        plan_hire_count: [
          {
            required: true,
            message: '请输入雇佣人数',
            trigger: 'blur'
          },
          {validator: validatorPlanHireCount, trigger: 'blur'}
        ],
        work_location: [
          {
            required: true,
            message: '请选择您的办公方式',
            trigger: 'blur'
          }
        ],
        work_county_id: [
          {
            required: true,
            validator: validatorLocation,
            trigger: 'blur'
          }
        ],
        work_address: [
          {
            required: true,
            validator: validatorLocation,
            trigger: 'blur'
          }
        ],
        pay_way: [
          {
            required: true,
            message: '请选择要怎么付款',
            trigger: 'blur'
          }
        ],
        pay_channel: [
          {
            required: true,
            message: '请选择资金托管的方式',
            trigger: 'blur'
          }
        ],
        hourly_budget_min: [{validator: validatorHourlyBudget, trigger: 'blur'}],
        hourly_budget_max: [{validator: validatorHourlyBudget, trigger: 'blur'}],
        max_hour_per_max: [{validator: validatorDayMaxHour, trigger: 'blur'}],
        plan_period_count: [{validator: validatorHourWeek, trigger: 'blur'}],
        fixed_budget_min: [{validator: validatorFixedBudget, trigger: 'blur'}],
        fixed_budget_max: [{validator: validatorMaxFixedBudget, trigger: 'blur'}],
        bid_end_time: [{validator: validatorBidEndTime, trigger: 'change'}],
        ask_coin: [{validator: validatorAskCoin, trigger: 'change'}],
        other: [
          {
            required: true,
            message: '请设置其他设置项',
            trigger: 'change'
          }
        ],
        checkedc: [
          {
            required: true,
            message: '请阅读并同意才可以进行下一步',
            trigger: 'change'
          }
        ],
        invoice_type: [
          {
            required: true,
            message: '请选择发票类型',
            trigger: 'change'
          }
        ],
        mobile: [{validator: validatorMobile, trigger: 'blur'}],
        code: [{validator: validatorCode, trigger: 'blur'}]
      },
      codemsg: '发送验证码',
      wait: 60,
      canClick: true,
    };
  },
  computed: {
    planPrice() {
      return ( this.taskForm.fixed_budget_max > this.taskForm.fixed_budget_min && this.sectionShow ) ?
          this.taskForm.fixed_budget_max : this.taskForm.fixed_budget_min;
    },
    StartTimeOption() {
      return {
        disabledDate(time) {
          let yesterday = (new Date().getTime() - 3600 * 24 * 1000)
          return time.getTime() < yesterday
        }
      }
    },
    EndTimeOption() {
      const _this = this
      return {
        disabledDate(time) {
          let lastTime = new Date().getTime()
          return time.getTime() < lastTime
        }
      }
    },
    OpenTimeOption() {
      const _this = this
      return {
        disabledDate(time) {
          let lastTime = new Date(_this.taskForm.bid_end_time).getTime()
          return time.getTime() < lastTime
        }
      }
    },
    isLogin() {
      return getToken()
    },
    stepZero() {
      const {subject, content} = this.taskForm;
      return {
        subject,
        content
      };
    },
    stepOne() {
      const {skill_ids, cat_id} = this.taskForm;
      return {
        skill_ids,
        cat_id
      };
    },
    stepTwo() {
      const {
        plan_hire_count,
        work_location,
        work_county_id,
        work_address,
        pay_way,
        hourly_budget_min,
        max_hour_per_max,
        plan_period_count,
        fixed_budget_min,
        pay_cycle
      } = this.taskForm;

      var workItem = 'ok';
      if (work_location == 2 && (work_county_id == '' || work_address == '')) {
        // 工作方式
        workItem = '';
      }
      if (work_location == '') {
        workItem = '';
      }
      var payWayItem = 'ok';
      if (pay_way == 1 && (fixed_budget_min == '')) {
        // 固定
        payWayItem = '';
      }
      if (pay_way == 2 && (hourly_budget_min == '' || max_hour_per_max == '' || plan_period_count == '')) {
        // 时薪
        payWayItem = '';
      }
      if (pay_way == '') {
        payWayItem = '';
      }

      if( this.currentStagePercent !=100 ) {
        payWayItem = ''
      }
      return {
        plan_hire_count,
        work_location,
        workItem,
        pay_way,
        payWayItem,
        pay_cycle
      };
    },
    theTotalPercent() {
      let thePercent = 0
      this.taskForm.stages.forEach( item => {
        thePercent = thePercent + parseInt( item.scale )
      })
      return thePercent
    }
    /*  isCheck(){
       return this.taskForm.checkedc==true ?false :true
   }, */
  },
  watch: {
    stepZero: {
      handler(newv) {
        this.checkNextBtnStyle(0, newv);
      },
      immediate: true
    },
    stepOne: {
      handler(newv) {
        this.checkNextBtnStyle(1, newv);
      },
      immediate: false
    },
    stepTwo: {
      handler(newv) {
        this.checkNextBtnStyle(2, newv);
      },
      immediate: false
    },
    theTotalPercent: {
      handler(newv) {
        this.currentStagePercent = newv
      },
      immediate: true
    }
  },
  created() {
    this.loadTaskConfigData();
    this.loadAllSkillCategory();
    this.loadinvoiceTypeList()
  },
  mounted() {
    this.redirect_url = this.$route.query.redirect_url
    this.taskId = this.$route.params.id;
    this.loadInfo();
  },
  methods: {
    async loadAllSkillCategory() {
      const res = await getSkillsCategory();
      if (res.statusCode == 200) {
        this.categorys = res.data;
      }
    },
    async loadinvoiceTypeList() {
      const res = await getInvoiceServices()
      if (res.statusCode == 200) {
        this.invoiceTypeList = res.data
      }
    },
    async loadInfo() {
      this.editPageLoadShow = true
      const res = await getPrivateInfo({sn: this.taskId});
      if (res.statusCode == 200) {
        if (res.data) {
          this.taskForm = Object.assign( this.taskForm, res.data )

          this.taskForm.attrs.map((item, key) => {
            item.name = item.file_name
            this.$set(this.attrs, key, item)
          })

          if(this.taskForm.fixed_budget_max > 0 ){
            this.sectionShow = true
          }
          this.taskForm.cat_id = parseInt(this.taskForm.cat_id)
          this.taskForm.pay_channel = this.taskForm.pay_channel.toString()
          if (this.taskForm.staging_config_array.length > 0) {
            this.taskForm.stages = [
                ...this.taskForm.staging_config_array
            ]
          }
          this.taskForm.checkedc = true
        }
        this.editPageLoadShow = false
      } else if (res.statusCode == 511) {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '请先登录'
        })
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '此项目您无权你修改'
        })
        return;
      }
    },
    toSendCode() {
      if (this.taskForm.mobile == '') {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '请先填写手机号'
        });
        return;
      } else {
        this.sendCode({account: this.taskForm.mobile, code_type: 2});
      }
    },
    async sendCode(data) {
      const result = await requestSendCode(data);
      if (result.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: result.data
        });
        this.codemsg = '60秒';
        this.canClick = false;
        let clock = window.setInterval(() => {
          this.wait--;
          this.codemsg = this.wait + '秒';
          if (this.wait < 0) {
            //当倒计时小于0时清除定时器
            window.clearInterval(clock);
            this.codemsg = '重新发送验证码';
            this.wait = 60;
            this.canClick = true;
          }
        }, 1000);
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: result.message
        });
      }
    },
    async searchSkills(data) {
      this.loading = true;
      const res = await getRecommandskills(data);
      if (res.statusCode == 200) {
        this.skill_cat_id = res.data.category != '' ? Number(Number(res.data.category.id)) : '';
        this.loading = false;
      }
    },
    loadTaskConfigData() {
      getTaskConfigData().then(res => {
        if (res.statusCode == 200) {
          this.taskConfig = res.data;
        }
      });
    },
    async submit(data) {
      this.publishLoading = true
      const res = await updateTask(data);
      if (res.statusCode == 200) {
        this.$notify({
          type: 'success',
          title: '通知',
          message: '修改成功！'
        });
        this.publishLoading = false

        this.$redirectUrl.jumpToFrom('/auth/task/' + res.data.sn)
      } else {
        this.$notify({
          type: 'error',
          title: '通知',
          message: res.message
        });
        this.publishLoading = false
      }
    },
    toSubmit() {
      this.taskForm.other = 'success';
      if( this.taskForm.fixed_budget_min > this.taskForm.fixed_budget_max || !isNumber(this.taskForm.fixed_budget_max) ){
        this.taskForm.fixed_budget_max = this.taskForm.fixed_budget_min;
      }
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          var periodCount = 0;
          var percentContinue = true
          switch (this.taskForm.pay_cycle) {
            case 1:
              periodCount = this.taskForm.plan_period_count
              break;
            case '1':
              periodCount = this.taskForm.plan_period_count
              break;
            case 2:
              periodCount = this.taskForm.plan_period_count
              break;
            case '2':
              periodCount = this.taskForm.plan_period_count
              break;
            case 3:// 一次性发放
              periodCount = 1
              break;
            case 4:// 分期发放计算长度
              var percentTotal = 0;
              this.taskForm.stages.map(item => {
                percentTotal = (parseInt(item.scale) + percentTotal)
              })
              if (percentTotal != 100) {
                this.$message.error('分期支出的比例必须是100%')
                percentContinue = false
              }
              periodCount = this.taskForm.stages.length
              break;
          }
          if (!percentContinue) {
            return false;
          }

          // 处理用户手动输入的垃圾
          let theSkillId = this.taskForm.skill_ids.map(kid => {
            if (typeof kid == 'string') {
              return {id: 0, name: kid}
            } else {
              return kid
            }
          })

          let postData = {
            id: this.taskForm.id,
            work_location: this.taskForm.work_location, // 工作地点
            work_province_id: this.taskForm.work_province_id,
            work_county_id:  this.taskForm.work_county_id,
            work_city_id:  this.taskForm.work_city_id,
            work_address: this.taskForm.work_address,
            pay_way: this.taskForm.pay_way, // 支付方式 1-固定费率 2-时薪
            hourly_budget_min: this.taskForm.hourly_budget_min == '' ? 0 : this.taskForm.hourly_budget_min, //时薪 最小
            hourly_budget_max: this.taskForm.hourly_budget_max == '' ? 0 : this.taskForm.hourly_budget_max, //时薪 最大
            max_hour_per_max: this.taskForm.max_hour_per_max, //时薪 单日最大工时
            plan_period_count: periodCount, // 预计多少周期
            fixed_budget_min: this.taskForm.fixed_budget_min, //最低预算金额/元
            fixed_budget_max: this.sectionShow ? this.taskForm.fixed_budget_max : 0, //最高预算金额/元
            pay_cycle: this.taskForm.pay_cycle, // 支付周期 1-week 2-month 3-full 4-staging
            pay_channel: this.taskForm.pay_channel, // 托管方式 1-平台 2-合作银行
            subject: this.taskForm.subject, // 任务标题
            content: this.taskForm.content, // 需求
            attr_ids: this.taskForm.attr_ids, // 附件
            skill_ids: theSkillId,
            plan_hire_count: this.taskForm.plan_hire_count, //雇佣人数
            cat_id: this.taskForm.cat_id, // 技能分类
            staging_config: this.taskForm.stages, // 分期比例
            bid_end_time: this.taskForm.bid_end_time, // 截止招标时间
            is_ask_coin: this.taskForm.is_ask_coin ? 'y' : 'n',
            ask_coin: this.taskForm.is_ask_coin ? this.taskForm.ask_coin : 0,
            is_open_bids: this.taskForm.is_open_bids,
            mobile: this.taskForm.mobile,
            code: this.taskForm.code,
            invoice_type: this.taskForm.invoice_type
          };
          this.submit(postData);
        } else {

          this.$notify({
            type: 'error',
            message: '请根据实际要求完善任务资料'
          });
          return false;
        }
      });
    },
    toPreview() {
      this.dialogVisible = true;
    },
    resetForm() {
      this.$refs.taskForm.resetFields();
    },
    // 在文件上传之前限制文件的大小
    beforeAvatarUpload(file) {
      this.uploadLoading = true
      let fileName = file.name;
      let pos = fileName.lastIndexOf('.');
      let lastName = fileName.substring(pos, fileName.length);
      // 可以上传的类型
      let fileExts = ['.jpg', '.jpeg', '.png', '.txt', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv', '.zip', '.rar'];
      if (!this.$store.getters.inArray(lastName.toLowerCase(), fileExts)) {
        this.uploadLoading = false
        this.$message.error('文件必须为.jpg .jpeg, .png .pdf .txt .doc .docx .zip .rar .xls .xlsx .csv类型');
        return false;
      }
      const clt = file.size / 1024 < 500;
      this.isLt25M = parseInt(this.isLt25M) + parseInt(clt);
      if (this.isLt25M > 25) {
        this.uploadLoading = false
        this.$message.error('上传的附件总大小不可以超过 25M');
        return false;
      }
    },
    uploadAttrSuccess(res, file) {
      if (res.statusCode == 200) {
        this.taskForm.attr_ids.push(res.data[0].fileid)
        this.attrs.push(res.data[0])
      } else {
        this.$notify({
          type: 'error',
          title: '文件上传通知',
          message: res.message
        })
      }
      this.uploadLoading = false
    },
    removeAttr(item, key) {
      this.attrs.splice(key, 1)
      let index = this.taskForm.attr_ids.indexOf(item.id);
      if (index < 0) {
        index = this.taskForm.attr_ids.indexOf(item.fileid);
      }
      if (index != -1) {
        this.taskForm.attr_ids.splice(index, 1)
      }
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.taskForm.skills = this.taskForm.skills.filter(item => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.taskForm.skills = [];
      }
    },
    // 点击追加推荐技能
    clickAppendSkill(skill) {
      var canAppend = true;
      this.skills.forEach(ele => {
        if (ele.id == skill.id) {
          canAppend = false;
        }
      });
      if (canAppend) {
        this.skills.push(skill);
        this.current_skills.push(skill)
        this.taskForm.skill_ids.push(skill.id);
      }
    },
    changeWorkLocation(val) {
      this.taskForm.work_location = val;
    },
    changePayCycle(val) {
      this.taskForm.pay_cycle = val;
    },
    regionBack(region) {
      this.regionData = region
      this.taskForm.work_province_id = region[0];
      this.taskForm.work_city_id = region[1];
      this.taskForm.work_county_id = region[2];
    },
    addStage() {
      if (this.currentStagePercent == 100) {
        this.$message.error('已满 100% 不可以添加更多');
        return;
      }
      // 新增周期
      let index = (this.taskForm.stages.length + 1)
      if (index> 5) {
        this.$message.error('最多可以设置五期');
        return;
      }
      this.taskForm.stages.push({cycle: index, scale: 0});
      this.taskForm.stages = [
          ...this.taskForm.stages
      ]
    },
    removeStage(stage) {
      if ( (this.taskForm.stages.length - 1) < 2) {
        this.$message.error('最少设置二期');
        return;
      }
      console.log('del', 1)
      this.taskForm.stages.splice(stage, 1)
      this.taskForm.stages = [
          ...this.taskForm.stages
      ]
      // this.$forceUpdate()
      console.log(this.taskForm.stages)
    },
    toNext(key) {
      if (key == 1) {
        this.loadTaskConfigData();
      }
      this.stepArray.forEach(stepItem => {
        if (stepItem.key == parseInt(key) + parseInt(1)) {
          switch (stepItem.key) {
            case 1:
              this.searchSkills({subject: this.taskForm.subject, content: this.taskForm.content});
              break;
            case 3:
              this.showOrHideNext('hide', 3);
              this.nextStepBtn.push('disabled')
              break;
          }
          stepItem.isShow = true;
        }
      });
      this.$refs.PosOne.scrollIntoView({
        // 平滑滚动
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });

      this.nextStepBtn.forEach(btnitem => {
        if (btnitem.key == key) {
          btnitem.isShow = false;
        }
        if (btnitem.key == parseInt(key) + parseInt(1)) {
          btnitem.isShow = true;
          btnitem.disabled = true;
        }
        if (btnitem.key == 3) {
          btnitem.isShow = false;
        }
      });
    },
    enableNext(type, key) {
      this.nextStepBtn.forEach(btn => {
        if (key == btn.key) {
          switch (type) {
            case 'yes':
              btn.disabled = false;
              break;
            case 'no':
              btn.disabled = true;
              break;
          }
        }
      });
    },
    showOrHideNext(type, key) {
      if (this.nextStepBtn[4]) {
        return;
      }
	  var currentBtnShow = false
	  this.nextStepBtn.map( item => {
	  		  if (item.key > key && item.isShow) {
	  		    currentBtnShow = true
	  		  }
	  })
	  if(currentBtnShow){
	  		  return;
	  }
      var currentBtn = this.nextStepBtn[key]
      var nextLength = (key + 1) > 3 ? 3 : (key + 1);
      var nextBtn = this.nextStepBtn[nextLength]
      if (nextBtn.isShow == true) {// 如果下一步的展示 自己不触发
        return;
      }
      switch (type) {
        case 'show':
          currentBtn.isShow = true;
          break;
        case 'hide':
          currentBtn.isShow = false;
          break;
      }
    },
    checkNextBtnStyle(theKey, data) {
      var isContinue = true;
      for (let key in data) {
        if (data[key] == '') {
          isContinue = false;
        }
      }

      if (isContinue) {
        this.showOrHideNext('show', theKey);
        this.enableNext('yes', theKey);
      } else {
        this.enableNext('no', theKey);
      }
    },
    //确定要关闭dialog吗
    handleClose(done) {
      this.dialogVisible = false
    },
    checkedAddressName(province_id, city_id, county_id) {
      var region = this.$regionJson.data
      var address = ''
      region.forEach(province => {
        if (province.c == province_id) {
          address = province.n
        }
        province.s.forEach(city => {
          if (city.c == city_id) {
            address += '-' + city.n
          }
          city.s.forEach(country => {
            if (country.c == county_id) {
              address += '-' + country.n
            }
          })
        })
      })

      return address;
    },
    skillCallBack(data) {
      this.taskForm.skill_ids = data.skill_ids
      this.taskForm.cat_id = data.skill_cat_id
      this.taskForm.skill_cat_id = data.skill_cat_id
      this.current_skills = data.skills
    },

    isArrayFn(value) {
      if (typeof Array.isArray === "function") {
        return Array.isArray(value);
      } else {
        return Object.prototype.toString.call(value) === "[object Array]";
      }
    },
    changeSection(val) {
      this.sectionShow = val
      this.taskForm.fixed_budget_max = 0
    }
    /* goService(){
      window.open("http://www.gogowork.cn/help/info/62542dcebf93c14ad37454ad2e7e31c1")
    },
    goUserRes(){
      window.open("http://www.gogowork.cn/help/info/6253db653e5476238bc23eb22681f53c")
    } */
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input {
  margin-left: unset;
}

.taskPublish {
  background-color: #f4f4f4;
  padding-bottom: 250px;
}

.banner-width {
  width: 100%;

  .banner-img-font {
    background-image: url(~@/assets/banner/底图.jpg);
    width: 100%;
    height: 400px;
    background-size: cover;

    p {
      position: relative;
      width: 800px;
      margin: 0 auto;
      top: 60px;
      color: #fff;

      strong {
        font-size: 24px;
        display: block;
        margin-bottom: 23px;
      }

      li {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  // 任务发布表单
  .task-Publish {
    width: 800px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: -250px;

    .task-Publish-center {
      width: 720px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
    }

    // 草稿部分
    .taks-Publish-draft {
      margin-top: 40px;
      width: 100%;

      .taks-Publish-draft-left {
        float: left;
        margin-right: 10px;
        font-size: 16px;
      }

      .taks-Publish-draft-right {
        float: left;
        font-size: 12px;
        color: #575757;

        li {
          margin-bottom: 10px;
        }

        button {
          margin-top: 10px;
          width: 150px;
          height: 32px;
          background: #e4007f;
        }
      }
    }

    //表单部分
    .task-Publish-Form {
      margin-top: 20px;
      margin-bottom: 60px;
      // 任务需求
      .task-accessory1 {
        width: 720px;
        height: 37px;
        display: flex;
        align-items: center;

        .task-accessory1-left {
          margin-left: 10px;
          margin-right: 20px;
          width: 60px;
        }

        .task-accessory1-right {
          width: 560px;
          display: flex;
          height: 37px;
          align-items: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            line-height: 32px;
            font-size: 12px;
            color: #575757;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .fileImg {
          margin-left: 25px;
          width: 10px;
          cursor: pointer
        }

        svg {
          cursor: pointer;
          font-size: 12px;
        }
      }

      // 上传附件的上传部分
      .upload-demo {
        font-size: 14px;

        .upload-center {
          line-height: 60px;
        }

        span {
          margin-left: 10px;
          color: #575757;
        }
      }

      // 需要的技能
      .task-work {
        span {
          font-size: 12px;
          color: #575757;
        }

        strong {
          font-size: 14px;
          margin-right: 10px;
        }
      }

      //输入技能
      .skill-Strong {
        display: block;
        line-height: 1;
        margin-top: 10px;
        color: #e4007f;
        font-size: 12px;
      }

      .skill-p {
        line-height: 1;
        margin-top: 10px;
        font-size: 14px;
        overflow: hidden;

        span {
          font-size: 12px;
          color: #575757;
          margin-right: 10px;
        }

      }
      // 工作地点
      .task-workAddress-border {
        width: 720px;
        display: flex;
        justify-content: space-between;
        /* border: 1px solid red; */
        height: 95px;

        .task-workAddress-border1,
        .task-workAddress-border2 {
          border-radius: 4px;
          cursor: pointer;
          width: 350px;
          border: 1px solid #b5b5b5;
          display: flex;

          .task-workAddress-border1-imgBorder {
            height: 95px;
            display: flex;
            align-items: center;

            svg {
              font-size: 48px;
              margin-left: 20px;
            }
          }

          .task-workAddress-border1-fontBorder {
            margin-left: 20px;

            strong {
              font-size: 14px;
              font-weight: 550;
              position: relative;
              top: 10px;
            }

            p {
              line-height: 16px;
              font-size: 12px;
              color: #575757;
            }
          }
        }
      }

      // 要怎么付款
      .task-money-border {
        width: 720px;
        display: flex;
        justify-content: space-between;
        height: 95px;

        .task-money-border1,
        .task-money-border2 {
          border-radius: 4px;
          cursor: pointer;
          width: 350px;
          height: 95px;
          border: 1px solid #b5b5b5;
          display: flex;

          .task-money-border1-imgBorder {
            height: 95px;
            display: flex;
            align-items: center;

            svg {
              margin-left: 20px;
              font-size: 48px;
            }
          }

          .task-money-border1-fontBorder {
            margin-left: 20px;

            strong {
              font-weight: 550;
              position: relative;
              top: 3px;
              font-size: 14px;
            }

            p {
              line-height: 20px;
              font-size: 12px;
              color: #575757;
            }

            b {
              position: relative;
              top: -28px;
            }
          }
        }
      }

      // 预算和薪资结算周期
      .task-budget-border {
        border-radius: 4px;
        margin-top: 10px;
        width: 720px;
        height: 195px;
        border: 1px solid #b5b5b5;
        display: flex;

        .task-budget-boder-left,
        .task-budget-boder-right {
          margin-top: 20px;
          width: 330px;
          height: 145px;
          margin-left: 20px;

          h2 {
            margin-top: -12px;
            font-size: 16px;
            margin-bottom: -16px;
          }

          span {
            font-size: 12px;
          }

          .task-budget-boder-left-span {
            position: relative;
            top: 2px;
          }

          .sectionBtn {
            cursor: pointer;
          }

          /deep/ .el-input-number {
            margin: 0 5px;
          }

          /deep/ .el-form-item__error {
            margin: 0;
            padding: 0;
          }

          p {
            font-size: 14px;
            position: relative;
            top: 5px;
            color: #575757;

            span {
              position: relative;
              left: -4.5px;
            }
          }

          b {
            font-size: 12px;
            color: #575757;
            position: relative;
            left: -30px;
            font-weight: 500;
          }
        }

        .long-work {
          position: relative;
          top: 2px;
        }

        .task-budget-boder-right {
          padding-left: 20px;
          box-sizing: border-box;
          border-left: 1px solid #b5b5b5;

          .task-budget-boder-right-radio {
            width: 310px;
            line-height: 37px;
            border-bottom: 1px solid #b5b5b5;
            font-size: 14px;

            .radio2 {
              margin-left: 54px;
            }

            input {
              margin-right: 8px;
            }
          }

          .task-budget-boder-right-week {
            p {
              position: relative;
              top: 5px;
              left: 2px;
              height: 80px;

              .task-budget-boder-right-week-span {
                position: relative;
                top: -18px;
                color: #575757;
              }

            }

            .task-budget-boder-right-week-border {
              position: relative;
              height: 30px;
              top: -12px;
            }
          }
        }
      }

      //支付固定价格
      .task-fixed-money-border {
        margin-top: 10px;

        .task-fixed-money-border-you {

          .title {
            display: flex;
            align-items: center;
            font-size: 18px;
          }

          span {
            font-size: 12px;
            color: #575757;
          }
        }

        // 薪资结算
        .task-fixed-money-border-period {
          width: 720px;

          strong {
            font-size: 18px;
            position: relative;
            top: -12px;
            margin-bottom: 12px;
          }

          .task-fixed-money-border-period-border {
            display: flex;
            justify-content: space-between;
            position: relative;
            top: -13px;

            .task-fixed-money-border-period-border-left {
              width: 150px;
              height: 82px;

              .task-fixed-money-border-period-border-left1,
              .task-fixed-money-border-period-border-left2 {
                border-radius: 4px;
                width: 150px;
                height: 36px;
                border: 1px solid #b5b5b5;
                box-sizing: border-box;

                .task-fixed-money-border-period-border-left1-center {
                  line-height: 36px;
                  display: flex;
                  align-items: center;

                  svg {
                    display: block;
                    font-size: 24px;
                    margin-left: 20px;
                  }

                  span {
                    margin-left: 10px;
                    font-size: 14px;
                  }
                }
              }

              .task-fixed-money-border-period-border-left2 {
                margin-bottom: 10px;
              }
            }

            .task-fixed-money-border-period-border-right {
              border-radius: 4px;
              width: 550px;
              height: 82px;
              border: 1px solid #DCDDDD;

              span {
                margin-left: 10px;
                color: #575757;
                font-size: 12px;
                display: block;
              }
            }

            .task-stages {
              width: 550px;
              padding-bottom: 20px;
              border: 1px solid #b5b5b5;

              img {
                width: 16px;
                height: 16px;
              }

              .task-stages-center {
                width: 530px;
                margin: 0 auto;
                margin-top: 10px;

                .task-stages-header {
                  position: relative;
                  top: -14px;
                  height: 36px;
                  border-bottom: 1px solid #b5b5b5;

                  span {
                    font-size: 12px;
                    color: #575757;
                  }
                }

                .task-stages-bottom {
                  display: flex;
                  justify-content: space-between;

                  .task-stages-left {
                    width: 339px;
                    border-right: 1px solid #b5b5b5;

                    li {
                      position: relative;
                      top: -5px;
                      margin-bottom: 10px;
                    }

                    .task-stages-left-three {
                      width: 320px;
                      display: flex;
                      height: 24px;
                      align-items: center;
                      color: #575757;
                      justify-content: space-between;

                      .task-stages-lef1 {
                        margin-right: 40px;

                        strong {
                          font-size: 14px;
                          position: relative;
                          top: 0px;
                          font-weight: 550;
                        }
                      }

                      .task-stages-left2 {
                        width: 200px;
                        display: flex;
                        justify-content: space-between;

                        /deep/ .el-select {
                          width: 100px;
                        }

                        .task-stages-percent {
                          margin-left: -10px;
                        }
                      }

                      .task-stages-left3 {
                        display: flex;
                        cursor: pointer;
                        line-height: 24px;
                        margin-left: 20px;

                        img {
                          position: relative;
                          top: 3px;
                        }
                      }
                    }

                    .task-stages-add {
                      cursor: pointer;
                      width: 320px;
                      line-height: 24px;
                      background: #f4f4f4;
                      text-align: center;
                      font-size: 12px;
                      margin-top: -5px;

                      span {
                        color: #00a2e6;
                        margin-right: 5px;
                        font-size: 14px;
                      }
                    }
                  }

                  .task-stages-right {
                    ul {
                      position: relative;
                      top: -15px;
                      height: 78px;

                      li {
                        font-size: 12px;
                        color: #575757;
                        height: 13px;
                        margin-bottom: 10px;

                        span {
                          color: #e4007f;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // 设置投标时间
      .task-end-time {
        margin-top: 10px;

        span {
          font-size: 12px;
          color: #575757;
        }

        .task-time-border {
          width: 720px;
          display: flex;
          justify-content: left;

          .task-start-time-border {
            margin-right: 40px;
          }

          strong {
            margin-right: 10px;
          }

          /deep/ .el-date-editor {
            width: 250px;
          }
        }
      }

      //项目资金您想要以哪种方式托管
      .task-collocation {
        .task-collocation-center {
          width: 720px;
          display: flex;
          justify-content: space-between;

          img {
            width: 24px;
            height: 24px;
          }

          .task-collocation-left,
          .task-collocation-right {
            width: 350px;
            height: 140px;

            ul {
              font-size: 12px;
              margin-top: -10px;
              margin-left: 45px;

              li {
                height: 12px;
                margin-bottom: 10px;
              }

              .task-collocation-left-img {
                height: 24px;
                position: relative;
                top: 15px;
                display: flex;
                align-items: center;

                span {
                  margin-right: 5px;
                }

                svg {
                  font-size: 20px;
                  margin-right: 10px;
                }
              }

              img {
                height: 24px;
                margin-right: 20px;
              }

              .task-collocation-left-imgs {
                img {
                  position: relative;
                  top: -2px;
                }
              }
            }

            /deep/ .el-radio {
              margin-top: 20px;
              margin-left: 20px;
            }

            /deep/ .el-radio__label {
              font-size: 16px;
            }
          }
        }
      }

      // 确定您的联系方式
      .task-phone {
        button {
          width: 130px;
          height: 40px;
          background: #00a2e6;
          font-size: 14px;
          color: #fff;
        }

      }

      .task-checked {
        width: 360px;
        margin: 0 auto;
        font-size: 14px;

        span {
          color: #00a2e6;
        }
      }

      // 底部两个按钮
      .task-two-buttons {
        margin: 20px 0px;

        .task-two-button-center {
          width: 440px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          line-height: 36px;
          text-align: center;

          button {
            width: 200px;
            height: 24px;
            font-size: 14px;
            background-color: #00a2e6;
            color: #fff;
          }

          .task-two-button {
            /deep/ span {
              position: relative;
              top: -5px;
            }
          }

          .task-one-button {
            background-color: #EDEDED;
            color: #00B4FF;
            font-size: 14px;

            /deep/ span {
              position: relative;
              top: -5px;
            }
          }
        }
      }

      // 弹出框
      .dialog-border {
        .task-dialog-center {
          width: 720px;
          margin: 0 auto;
          display: flex;

          h2 {
            font-size: 18px;
            margin-bottom: 20px;
          }

          .task-dialog-center-left {
            width: 420px;

            .task-dialog-center-left1 {
              line-height: 24px;

              p {
                font-size: 12px;
                line-height: 20px;
                text-indent: 25px;
              }
            }

            .task-dialog-center-left2 {
              display: flex;
              margin-top: 20px;
              line-height: 30px;
              border-bottom: 1px solid #b5b5b5;

              .task-dialog-center-left2-font1 {
                span {
                  display: block;
                  width: 50px;
                  line-height: 34px;
                }
              }

              .task-dialog-center-left2-font2 > li:last-of-type {
                margin-bottom: 20px;
              }

              .task-dialog-center-left2-font2 {
                li {
                  display: flex;

                  i {
                    width: 24px;
                    height: 24px;
                  }

                  span {
                    display: inline-block;
                    width: 340px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
              }
            }

            .task-dialog-center-left3 {
              margin-top: 20px;

              p {
                font-size: 12px;
                display: flex;
                flex-wrap: wrap;
              }

              strong {
                display: block;
                height: 30px;
                line-height: 22px;
                font-size: 14px;
                color: #575757;
                margin-right: 10px;
              }

              span {
                display: inline-block;
                padding: 5px 10px;
                background-color: #00a2e6;
                border-radius: 10px;
                margin-right: 10px;
                margin-bottom: 10px;
                font-size: 12px;
                color: #fff;
              }
            }
          }

          .task-dialog-center-right {
            width: 249px;
            height: 260px;
            margin-top: 35px;
            margin-left: 50px;

            .task-dialog-center-right-header {
              width: 249px;
              line-height: 36px;
              background-color: #00a2e6;
              font-size: 12px;
              color: #fff;
              .flex;
              align-items: center;

              svg {
                font-size: 16px;
                margin-left: 10px;
                margin-right: 10px;
              }
            }

            .task-dialog-center-right-three-border {
              display: flex;
              margin-top: 20px;
              justify-content: space-around;
              align-items: center;
              padding-bottom: 10px;

              .task-dialog-center-right-three-border1 {
                font-size: 12px;
                text-align: center;

                svg {
                  font-size: 20px;
                }
              }

              span {
                position: relative;
                top: 10px;
              }
            }

            .task-dialog-back1 {
              width: 249px;
              line-height: 36px;
              background: #f4f4f4;
              margin-top: 20px;

              svg {
                font-size: 16px;
                margin-left: 10px;
                margin-right: 10px;
              }
            }

            .task-dialog-back2 {
              width: 249px;
              line-height: 36px;

              svg {
                font-size: 16px;
                margin-left: 10px;
                margin-right: 10px;
              }
            }

            .task-dialog-back3 {
              background: #f4f4f4;
              width: 249px;
              display: flex;

              svg {
                font-size: 16px;
                margin-left: 10px;
                margin-right: 10px;
                position: relative;
                top: 13px;
              }

              ul {
                margin-top: 13px;

                li {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }

      .task-amount {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .title {
          display: flex;
          align-items: center;
          color: #000000;
          font-size: 18px;
          font-weight: normal;

          span:last-of-type {
            margin-left: 5px;
          }
        }

        .content {
          border-radius: 4px;
          margin: 20px 0px;
          padding: 20px;
          width: 100%;
          box-shadow: 0 0 6px #dedede;

          .money {
            display: flex;
            justify-content: left;
            align-items: center;

            /deep/ .el-input {
              width: 100px !important;
            }

            .sectionBtn {
              margin: -20px 10px 0px 10px;
              font-size: 14px;
              color: #9CA3AF;
              cursor: pointer;
            }

            .person {
              margin-right: 20px;

              span:first-of-type {
                color: #d4237a;
              }

              span:last-of-type {
                margin-left: 5px;
                width: 80px;
              }

              /deep/ .el-input {
                width: 150px;
              }

              /deep/ .el-form-item__content {
                display: flex;
                align-items: center;
                justify-content: left;
              }

              /deep/ .el-form-item__error {
                width: 200px;
              }

              .unit {
                color: #9CA3AF !important;
                margin-right: 5px;
              }
            }
          }

          .task-fixed-money-border-period-border {
            display: flex;
            flex-direction: column;

            .task-fixed-money-border-period-border-left {
              display: flex;
              justify-content: left;
              align-items: center;
              width: 100%;

              /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
                background-color: #00ace6 !important;
              }

              .task-fixed-money-border-period-border-left1,
              .task-fixed-money-border-period-border-left2 {
                border-radius: 4px;
                width: 150px;
                height: 36px;
                border: 1px solid #b5b5b5;
                box-sizing: border-box;

                .task-fixed-money-border-period-border-left1-center {
                  line-height: 36px;
                  display: flex;
                  align-items: center;

                  svg {
                    display: block;
                    font-size: 24px;
                    margin-left: 20px;
                  }

                  span {
                    margin-left: 10px;
                    font-size: 14px;
                  }
                }
              }

              .task-fixed-money-border-period-border-left2 {
                margin-bottom: 10px;
              }
            }

            .task-fixed-money-border-period-border-right {
              margin-top: 10px;
              border-radius: 4px;
              height: 82px;
              padding-top: 10px;
              border: 1px solid #DCDDDD;

              span {
                margin-left: 10px;
                color: #575757;
                font-size: 12px;
                display: block;
              }
            }

            .task-stages {
              margin-top: 10px;
              padding-bottom: 20px;
              border: 1px solid #DCDDDD;

              img {
                width: 16px;
                height: 16px;
              }

              .task-stages-center {

                .task-stages-header {
                  display: flex;
                  align-items: center;
                  margin-left: 10px;
                  height: 30px;
                  border-bottom: 1px solid #DCDDDD;

                  span {
                    font-size: 12px;
                    color: #575757;
                  }
                }

                .task-stages-bottom {
                  display: flex;
                  justify-content: left;
                  margin-top: 10px;

                  .task-stages-left {
                    width: 400px;
                    border-right: 1px solid #DCDDDD;
                    padding: 10px 20px;

                    li {
                      margin-bottom: 15px;
                    }

                    .task-stages-left-three {
                      display: flex;
                      align-items: center;
                      color: #575757;
                      justify-content: left;

                      .task-stages-lef1 {
                        margin-right: 20px;

                        strong {
                          font-size: 14px;
                          position: relative;
                          top: 0;
                          font-weight: 550;
                        }
                      }

                      .task-stages-left2 {
                        width: 200px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;

                        /deep/ .el-select {
                          width: 100px;
                        }

                        .task-stages-percent {
                          margin-left: -10px;
                        }
                      }

                      .task-stages-left3 {
                        display: flex;
                        cursor: pointer;
                        margin-left: 20px;

                        img {
                          position: relative;
                          top: 3px;
                        }
                      }
                    }

                    .task-stages-add {
                      cursor: pointer;
                      width: 320px;
                      background: #f4f4f4;
                      text-align: center;
                      font-size: 12px;
                      border-radius: 8px;
                      padding: 5px;

                      span {
                        color: #00a2e6;
                        margin-right: 5px;
                        font-size: 14px;
                      }
                    }
                  }

                  .task-stages-right {
                    padding: 10px 20px;

                    ul {
                      height: 78px;

                      li {
                        font-size: 12px;
                        color: #575757;
                        height: 13px;
                        margin-bottom: 10px;

                        span {
                          color: #e4007f;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
    }
  }
}

/deep/ .el-form-item__label {
  font-size: 18px;
  color: #000000;
  font-weight: normal;
}

/deep/ .el-input__inner {
  font-size: 14px;
  color: #575757;
  border-color: #DCDDDD !important;
  border-radius: 4px !important;
}

/deep/ .el-textarea__inner {
  border-radius: 4px !important;
  font-size: 14px;
  color: #575757 !important;
  border-color: #DCDDDD !important;
}

/deep/ .el-upload-dragger {
  width: 720px;
  height: 60px;
}

/deep/ .el-input-number__decrease,
/deep/ .el-input-number__increase {
  width: 20px;
  height: 15px;
  font-size: 12px;
}

/deep/ .el-input-number__increase {
  width: 20px;
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: #000;
}


// 雇佣人数
/deep/ .hirePerson {
  /deep/ .el-form-item__error {
    left: 100px;
  }
}

/* 取消按钮 */
/deep/ .el-dialog__headerbtn {
  font-size: 20px;
}

/deep/ .el-link.el-link--primary {
  color: #00a2e6;
  font-weight: 400;
}

/deep/ .el-icon-loading {
  line-height: unset;
}

/deep/ .el-button.is-round {
  border-radius: 12px !important;
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 25px;

  button {
    width: 160px;
    background-color: #DCDDDD;
    color: #fff;
  }

  .bule {
    background-color: #00ace6;
  }
}

.money-input {
  display: flex;
  align-items: center;
  justify-content: left;

  .section-btn {
    margin: 0px 10px;
    height: 60px;
  }

  /deep/ .el-input__inner {
    border-right: unset !important;
  }

  /deep/ .el-input-group__append, .el-input-group__prepend {
    background-color: unset !important;
    padding: 0px 10px !important;
  }
}


/deep/ .el-form-item {
  margin-bottom: 22px !important;
}

/deep/.el-alert__description {
  line-height: 20px;
}

</style>
